import React from 'react';
import styled from 'styled-components';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useUI from 'src/hooks/use-ui';

import ContentWrapper from 'components/core/content-wrapper';
import StoreInfo from 'src/dispensary/components/store-info';
import { MainDispensaryLayout } from 'src/components/layouts/main-dispensary-layout';
import { useIsMobile } from 'src/hooks/use-is-mobile';
import { BreadCrumbs } from 'src/components/bread-crumbs';

export default function DispensaryInfoPage() {
  const { dispensary } = useDispensary();
  const { isDutchieMain } = useUI();
  const isMobile = useIsMobile();

  if (!dispensary) {
    return null;
  }

  return (
    <StyledContentWrapper>
      {isDutchieMain && (
        <BreadCrumbsContainer isMobile={isMobile}>
          <BreadCrumbs />
        </BreadCrumbsContainer>
      )}
      <StoreInfo dispensary={dispensary} />
    </StyledContentWrapper>
  );
}

const StyledContentWrapper = styled(ContentWrapper)`
  padding: 0 0 50px;
  ${({ theme }) => theme.breakpoints.up(`md`)} {
    padding: 44px 50px 150px;
  }
`;

const BreadCrumbsContainer = styled.div`
  margin: ${({ isMobile }) => (isMobile ? `25px 0 24px` : `-18px 0 48px -24px`)};
`;

DispensaryInfoPage.layout = MainDispensaryLayout;

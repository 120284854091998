import DispensaryInfoPage from 'src/dispensary/info';
import { getStaticPaths } from 'utils/ssr';
import { MainDispensaryLayout } from 'src/components/layouts/main-dispensary-layout';
import { generateConsumerDispensaryStaticProps } from 'src/dispensary/utils/ssr/generate-consumer-dispensary-static-props';

export { getStaticPaths };

export const getStaticProps = async (context) => generateConsumerDispensaryStaticProps(context);

DispensaryInfoPage.layout = MainDispensaryLayout;
export default DispensaryInfoPage;
